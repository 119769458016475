import React, { useMemo } from 'react';

import { Tag, Tooltip, Typography } from 'antd';

import './colorTag.less';

const { Text } = Typography;
export default function ColorTag({ item, closable = false, onClick, onClose, extraClasses, moreTags }) {
	const { name, color, nameToShow, hoverText } = item || {};
	const correctColor = useMemo(() => {
		const hexLength = color?.replace('#', '')?.length;
		if (!/^#[a-fA-F0-9]{2,8}$/.test(color)) {
			return '#888';
		}
		const validLengths = new Set([3, 4, 6, 8]);
		return validLengths.has(hexLength) ? color : color + 'f';
	}, [color]);
	const ellipsis = moreTags ? true : { tooltip: { placement: 'top', color: correctColor } };

	return (
		<Tooltip color={correctColor} rootClassName="tooltip-style-background br-40" placement="right" title={hoverText}>
			<Tag
				closable={closable}
				{...(onClose && { onClose })}
				key={`item=${item?._id}`}
				onClick={onClick}
				style={{ backgroundColor: correctColor, borderColor: correctColor }}
				className={`tag-style tag-color ${extraClasses} br-40`}>
				<Text ellipsis={ellipsis}>{nameToShow || name}</Text>
			</Tag>
		</Tooltip>
	);
}
