import React from 'react';

////////////////////////
//ticket TABLE_STRUCTURE
import {
	BuildingIcon,
	CalendarIcon,
	CertificateIcon,
	ClockIcon,
	Dashboard,
	DenyIcon,
	DialogIcon,
	DocumentIcon,
	FileBasicClassifierIcon,
	FolderIcon,
	IdIcon,
	IdSimpleIcon,
	LinkIcon,
	LocationIcon,
	LungsIcon,
	PhoneIcon,
	ProgressIcon,
	SignIcon,
	StatusSuccessIcon,
	TagIcon,
	UploadIcon,
	UserApproveIcon,
	UserCircleIcon,
	UserIcon,
	ViewIcon,
	TranslationIcon,
} from '../assets/images/icons';
import IPLocation from '../assets/images/icons/IPLocation';

////////////////////////
export const getTableStructure = departmentName => TABLE_STRUCTURE[departmentName];

export const TABLE_STRUCTURE = {
	coordination: {
		'Date Created': 'createdAt',
		'Open Number': 'ticketID',
		'Admission ID': 'patient.admissionId',
		'Patient Name': 'title',
		Coordinator: 'patient.coordinator',
		Status: 'status',
		Tag: 'tags',
		Language: 'language',
		Accepted: 'accepted.length',
		Broadcasted: 'broadcast.lastDate',
		Viewed: 'viewed',
		Updated: 'updatedAt',
		__types: {
			Broadcasted: 'dateCustomFormat',
		},
	},
	recruitment: {
		'Ticket #': 'ticketID',
		Created: 'createdAt',
		'Ticket Name': 'title',
		Source: 'source',
		Status: 'status',
		Tags: 'tags',
		'Assigned Recruiter': 'assignedTo.email',
		Updated: 'updatedAt',
		'Phone Number': 'applicant.primaryPhone',
		'Interview Date': 'interviewDate',
		'Interview Location': 'interviewLocation',
		Responded: 'numberOfResponses',
		'Last Responded': 'lastResponded',
		__types: {
			Updated: 'date-relative',
			'Last Responded': 'lastResponse',
			Responded: 'reponseNumber',
		},
	},
	hhau: {
		'Ticket #': 'ticketID',
		'Caregiver Code': 'studentCode',
		'First Name': 'student.firstName',
		'Last Name': 'student.lastName',
		Phone: 'student.primaryPhone',
		Language: 'language',
		Status: 'status',
		Tags: 'tags',
		'Training Type': 'type',
		'Total Hours': 'progression.totalHours',
		Progress: 'progression.progress',
		'Completed Date': 'progression.completedAt',
		'Applicant Type': 'applicantType',
		'Hire Date': 'hireDateTicket',
		'Expiration Date': 'expirationDate',
	},
	payroll: {
		'Ticket #': 'ticketID',
		Company: 'company',
		'Ticket Name': 'title',
		'Phone Number': 'caregiver.primaryPhone',
		'Caregiver Code': 'caregiver.code',
		Status: 'status',
		Tags: 'tags',
		'Assigned To': 'assignedTo.email',
		Language: 'caregiver.language',
		Viewed: 'viewed',
		Created: 'createdAt',
		Updated: 'updatedAt',
		__types: { Updated: 'date-relative' },
	},
	intake: {
		'Ticket #': 'ticketID',
		Created: 'createdAt',
		'Lead Name': 'title',
		Type: 'lead.leadType',
		Company: 'company',
		Source: 'source',
		Status: 'status',
		Tags: 'tags',
		'Phone Number': 'lead.primaryPhone',
		'Assigned To': 'assignedTo.email',
		Viewed: 'viewed',
		Updated: 'updatedAt',
		__types: { Updated: 'date-relative' },
	},
	compliance: {
		'Ticket #': 'ticketID',
		'Caregiver Name': 'title',
		'Caregiver Code': 'caregiver.code',
		'Expiring Documents': 'documents',
		Status: 'status',
		Tags: 'tags',
		'Assigned To': 'assignedTo.email',
		Language: 'language',
		Updated: 'updatedAt',
	},
	'value-based': {
		'Ticket #': 'ticketID',
		'Caregiver Name': 'title',
		'Caregiver Code': 'caregiver.code',
		Language: 'language',
		Status: 'status',
		Tags: 'tags',
		'Assigned To': 'assignedTo.email',
		Updated: 'updatedAt',
		__types: {
			Updated: 'date-relative',
		},
		Viewed: 'viewed',
	},
	caregiver: {
		'Caregiver Name': 'fullName',
		// 'First Name': "firstName",
		// 'Last Name': "lastName",
		'ID Number': 'entityID',
		'EMR ID': 'code',
		'Phone Number': 'primaryPhone',
		Language: 'language',
		Status: 'status',
	},
	patient: {
		'Patient Name': 'fullName',
		// 'First Name': "firstName",
		// 'Last Name': "lastName",
		'ID Number': 'entityID',
		'Admission ID': 'admissionId',
		'Phone Number': 'primaryPhone',
		Language: 'language',
		Status: 'status',
		Coordinator: 'coordinator',
	},
	ats: {
		'Application ID': 'ticketID',
		'Follow Up Date': 'followUpDate',
		'Applicant Type': 'type',
		'Application Date': 'createdAt',
		'First Name': 'applicant.firstName',
		'Last Name': 'applicant.lastName',
		'Phone Number': 'applicant.primaryPhone',
		Status: 'status',
		Tags: 'tags',
		Progress: 'atsProgress',
		Language: 'applicant.language',
		'File Location': 'documentsLocation',
		'Application Complete': 'applicationCompleted',
		'Assigned User': 'assignedTo.email',
		Documents: 'documentsCompleted',
		'I9 Verified': 'i9Verified',
		Orientation: 'orientation',
		'Hire Date': 'applicant.hireDate',
		City: 'applicant.location.city',
		'Denied Stages': 'stagesDenied',
		'Documents Uploaded': 'documentUploaded',
		'Linked Patient': 'linkedPatient',
	},
	bgcheck: {
		'Application ID': 'ticketID',
		'Applicant Type': 'type',
		'Application Date': 'createdAt',
		'First Name': 'applicant.firstName',
		'Last Name': 'applicant.lastName',
		Status: 'status',
		Tags: 'tags',
		'BG Date Submitted': 'bgCheckSubmitted',
		'BG Last Update': 'bgCheckLastUpdate',
		'Last Arla Check': 'bgCheckLastCheck',
		'BG Status': 'bgCheckStatus',
		'BG Document': 'bgCheckDocument',
	},
};

export const TABLE_ICONS_STRUCTURE = {
	coordination: {
		'Admission ID': <IdIcon />,
		'Date Created': <CalendarIcon />,
		'Open Number': <IdIcon />,
		'Patient Name': <UserIcon />,
		Accepted: <StatusSuccessIcon color="#0D0D0D" />,
		Broadcasted: <CalendarIcon />,
		Coordinator: <LinkIcon />,
		Language: <DialogIcon />,
		Status: <Dashboard />,
		Tag: <TagIcon />,
		Updated: <CalendarIcon />,
		Viewed: <ViewIcon />,
	},
	recruitment: {
		'Assigned Recruiter': <UserIcon />,
		'Interview Date': <CalendarIcon />,
		'Interview Location': <LocationIcon />,
		'Last Responded': <CalendarIcon />,
		'Verbal Orientation Date': <CalendarIcon />,
		'Phone Number': <PhoneIcon />,
		'Ticket #': <IdIcon />,
		'Ticket Name': <UserIcon />,
		Created: <CalendarIcon />,
		Responded: <IdSimpleIcon />,
		Source: <DialogIcon />,
		'Other Source': <DialogIcon />,
		Status: <StatusSuccessIcon color="#0D0D0D" />,
		Language: <TranslationIcon />,
		Tags: <TagIcon />,
		Updated: <CalendarIcon />,
		'Zip Code': <LocationIcon />,
		IP: <IPLocation />,
	},
	hhau: {
		'Applicant Type': <UserCircleIcon />,
		'Caregiver Code': <IdIcon />,
		'Completed Date': <CalendarIcon />,
		'Expiration Date': <CalendarIcon />,
		'First Name': <UserIcon />,
		'Hire Date': <CalendarIcon />,
		'Last Name': <UserIcon />,
		'Ticket #': <IdIcon />,
		'Total Hours': <IdSimpleIcon />,
		'Training Type': <FileBasicClassifierIcon />,
		Language: <DialogIcon />,
		Phone: <PhoneIcon />,
		Progress: <ProgressIcon />,
		Status: <StatusSuccessIcon color="#0D0D0D" />,
		Tags: <TagIcon />,
	},
	payroll: {
		'Assigned To': <UserIcon />,
		'Caregiver Code': <IdIcon />,
		'Phone Number': <PhoneIcon />,
		'Ticket #': <IdIcon />,
		'Ticket Name': <UserIcon />,
		Company: <BuildingIcon />,
		Created: <CalendarIcon />,
		Language: <DialogIcon />,
		Status: <StatusSuccessIcon color="#0D0D0D" />,
		Tags: <TagIcon />,
		Updated: <CalendarIcon />,
		Viewed: <ViewIcon />,
	},
	intake: {
		// 'Assigned To': 'assignedTo.email',
		// 'Lead Name': 'title',
		// 'Phone Number': 'lead.primaryPhone',
		// 'Ticket #': 'ticketID',
		// Company: 'company',
		// Created: 'createdAt',
		// Source: 'source',
		// Status: 'status',
		// Tags: 'tags',
		// Type: 'lead.leadType',
		// Updated: 'updatedAt',
		// Viewed: 'viewed',
		// __types: { Updated: 'date-relative' },
	},
	compliance: {
		'Assigned To': <UserIcon />,
		'Caregiver Code': <IdIcon />,
		'Caregiver Name': <UserIcon />,
		'Expiring Documents': <ClockIcon />,
		'Ticket #': <IdIcon />,
		Language: <DialogIcon />,
		Status: <StatusSuccessIcon color="#0D0D0D" />,
		Tags: <TagIcon />,
		Updated: <CalendarIcon />,
	},
	caregiver: {
		'Caregiver Name': <UserIcon />,
		'EMR ID': <IdIcon />,
		'ID Number': <IdIcon />,
		'Phone Number': <PhoneIcon />,
		Language: <DialogIcon />,
		Status: <StatusSuccessIcon color="#0D0D0D" />,
	},
	patient: {
		'Admission ID': <IdIcon />,
		'ID Number': <IdIcon />,
		'Patient Name': <UserIcon />,
		'Phone Number': <PhoneIcon />,
		Coordinator: <LinkIcon />,
		Language: <DialogIcon />,
		Status: <StatusSuccessIcon color="#0D0D0D" />,
	},
	ats: {
		'Applicant Type': <UserCircleIcon />,
		'Application Complete': <StatusSuccessIcon color="#0D0D0D" />,
		'Application Date': <CalendarIcon />,
		'Application ID': <IdIcon />,
		'Application Signed': <SignIcon />,
		'Assigned User': <UserApproveIcon />,
		'BG Date Submitted': <CalendarIcon />,
		'BG Document': <DocumentIcon />,
		'BG Last Update': <CalendarIcon />,
		'BG Status': <Dashboard />,
		'Competency New': <CertificateIcon />,
		'Denied Stages': <DenyIcon />,
		'Documents Uploaded': <UploadIcon />,
		'File Location': <FolderIcon />,
		'First Name': <UserIcon />,
		'Follow Up Date': <CalendarIcon />,
		'Hire Date': <CalendarIcon />,
		'I9 Verified': <StatusSuccessIcon color="#0D0D0D" />,
		'Interview Assessment': <StatusSuccessIcon color="#0D0D0D" />,
		'Last Arla Check': <CalendarIcon />,
		'Last Name': <UserIcon />,
		'Linked Patient': <LinkIcon />,
		'Performance Demonstrations': <Dashboard />,
		'Phone Number': <PhoneIcon />,
		'RN Signature': <SignIcon />,
		'Schedule Start': <CalendarIcon />,
		'Scheduled Date': <CalendarIcon />,
		'TB Screen New': <LungsIcon />,
		'TB Screen': <LungsIcon />,
		'Written Test': <SignIcon />,
		City: <BuildingIcon />,
		Competency: <CertificateIcon />,
		Documents: <DocumentIcon />,
		Language: <DialogIcon />,
		Orientation: <StatusSuccessIcon color="#0D0D0D" />,
		Progress: <ProgressIcon />,
		Status: <Dashboard />,
		Tags: <TagIcon />,
	},
};

export const AVOIDED_COLUMNS_TO_SORT = [
	'status',
	'tags',
	'atsProgress',
	'applicationCompleted',
	'tbScreen',
	'orientation',
	'stagesDenied',
	'accepted.length',
	'studentCode',
	'documentsLocation',
	'documentsCompleted',
	'competency',
	'i9Verified',
];

export const MIN_WIDTH_COLUMNS = { Status: 140, Tags: 200, Progress: 140, Phone: 140, 'Phone Number': 140 };
